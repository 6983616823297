@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}