@import "src/assets/styles/mixins.scss";

.header_wrapper {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7vw;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgb(255 255 255 / 10%),
    0 8px 16px rgb(197 197 197 / 20%), 0 12px 24px rgb(193 193 193 / 30%);
  height: 3.6875vw;
  background-color: #fff;

  .left {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 100%;
      scale: 1.3;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 0.625vw;
    height: 100%;

    .line {
      width: 1px;
      background-color: #1857a666;
      height: 1.875vw;
    }

    .edit_dashboard_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 1.6vw;
      gap: 0.5vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .language_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 0.4vw;
      gap: 0.4vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:first-child {
        height: 1.8vw;
      }

      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .language_options {
      @include flexColumn;
      transition: height 0.25s;
      overflow: hidden;
      justify-content: center;

      &.closed {
        height: 0;
      }

      &.open {
        width: 12.5vw;
        padding: 0.25vw;
      }

      .language_option {
        border: none;
        background-color: unset;
        text-align: left;
        margin: 0.2vw 0;
        display: flex;
        border-radius: 8px;
        padding: 0.4vw 0.4vw;
        font-size: 1vw;
        height: 2vw;

        img {
          margin-left: auto;
          height: 100%;
        }

        &.selected,
        &:hover {
          background-color: #e9f0f7;
        }
      }
    }

    .profile_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 0.4vw;
      gap: 0.4vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:first-child {
        height: 1.8vw;
      }
      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .profile_popover {
      display: flex;
      flex-direction: column;
      gap: 1vw;
      align-items: center;
      padding: 0.5vw 0.5vw;
      font-size: 1vw;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
        font-size: 1.2vw;
        padding: 0.5vw 2.5vw;
        background-color: #faad14; /* Màu nền chính của nút */
        color: white; /* Màu chữ */
        border: none; /* Không có viền */
        border-radius: 1000px; /* Bo góc nhẹ */
        cursor: pointer; /* Con trỏ chuột */
        transition: background-color 0.3s ease; /* Hiệu ứng chuyển màu mượt mà */

        &:hover {
          background-color: #ffc53e; /* Màu nền khi hover */
        }

        &:active {
          background-color: #d48806; /* Màu nền khi nhấn nút */
          transform: scale(0.98); /* Hiệu ứng co lại nhẹ */
        }
      }
    }
  }
}

@media (min-width: 2560px) {
  .header_wrapper {
    padding: 6px 7vw;

    .left {
      img {
        scale: 1;
      }
    }
  }
}

@media (min-width: 3200px) {
  .header_wrapper {
    padding: 10px 7vw;

    .left {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}
