.right_content {
  flex: 1;
  display: flex;
  flex-direction: column;

  .tools_box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pdf_btn {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      gap: 0.5vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:nth-last-child(1) {
        height: 1.8vw;
      }
    }

    .calendar_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 2px solid var(--border);
      padding: 0.5vw;
      gap: 0.5vw;
      max-height: 2.4vw;
      font-size: 1vw;

      img:nth-last-child(1) {
        height: 1.2vw;
      }
    }

    .vertical_line {
      width: 2px;
      background-color: var(--border);
      height: 1.5vw;
    }
  }

  .game_overview {
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    z-index: 10;

    .totalSpentTime {
      background-image: url("/assets/img/bg-total-time.webp");
      background-size: cover;
      background-position: center;
      display: flex;
      padding: 1.5vw 3vw;
      border-radius: 1vw;

      .time {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;

        img {
          flex: 1;
          max-width: 5vw;
        }

        span:first-child {
          font-size: 1vw;
        }
        span:last-child {
          font-weight: 900;
          font-size: 1.5vw;
        }
      }
    }
  }

  .game_detail {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    .game_detail_title {
      font-weight: 500;
      font-size: 1.2vw;
      text-align: left;
      margin-bottom: 0.5vw;
    }
  }

  .visible {
    opacity: 1;
  }
}

.table_nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
}

.customPopup {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  z-index: 10010;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .popup_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.close_btn {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 1vw;
  top: 1vw;
  color: #888888;
  background: unset;
  border: none;
}
