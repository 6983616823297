.rounded {
  border-radius: 1000px;
}

.button_gradient {
  color: white;
  background: linear-gradient(to bottom, #3ea5e8, #1857a6);
}

.button_primary {
  background-color: #f3b357;
  color: white;
}

.button_secondary {
  background-color: white;
  color: #344054;
  border: 1px solid #d0d5dd;
}

.text_gradient {
  background: #1857a6;
  background: linear-gradient(to top, #1857a6 0%, #3ea5e8 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cancel_btn {
  border: 1px solid #ee4343;
  color: #ee4343;
}

.size_large {
  font-size: var(--font-large);
}

.size_medium {
  font-size: var(--font-medium);
}

.size_small {
  font-size: var(--font-small);
}

.btn-large {
  padding: var(--font-small) var(--padding-large);
  font-size: var(--font-large);
}

.btn-medium {
  padding: var(--font-small) var(--padding-medium);
  font-size: var(--font-medium);
}

.btn-small {
  padding: var(--padding-small);
  font-size: var(--font-small);
}

div[data-testid="overlay"] {
  background-color: rgba(0, 0, 0, 0.5);
  .popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }
}

/* css cho thư viện react-day-picker */
.rdp-root {
  --rdp-accent-color: #4cb7f3; /* Change the accent color to indigo. */
  --rdp-accent-background-color: #76c8ff38; /* Change the accent background color. */
  /* Add more CSS variables here. */

  --rdp-range_start-date-background-color: #64c0ff;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
