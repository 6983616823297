.breadcrumb_wrapper {
  font-size: 0.875vw;
  padding: 2.22vw 7vw 1.4vw 7vw;
}

// @media (min-width: 2560px) {
//   .breadcrumb_wrapper {
//     font-size: 22px;
//     padding: 40px calc(7vw) 32px calc(7vw);
//   }
// }
