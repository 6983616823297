.customPopup {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  max-width: 50vw;
  text-align: center;
  z-index: 10010;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .popup_content {
    margin: 45px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      padding: 5px 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: 1px solid #ccc;
      font-family: "noto_sans_jp", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }

    .switching_btn {
      width: fit-content;
      align-items: center;
      background-color: var(--blue);
      border: none;
      border-radius: 10px;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 8px 10px;
      transition: all 0.25s;
      margin-top: 30px;
    }

    .input_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      width: 100%;

      label {
        flex: 1;
        margin-right: 10px;
        text-align: left;
      }

      input,
      select {
        flex: 2;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }
  }
}

.close_btn {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 1vw;
  top: 1vw;
  color: #888888;
  background: unset;
  border: none;
}
