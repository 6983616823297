.patientDetailPopup {
  min-width: 65vw;
  height: 78vh;
  border-radius: 1.5vw;
  background-color: #fff;
  text-align: center;
  z-index: 10010;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .header_popup {
    display: flex;
    width: 100%;
    padding: 1.25vw 6.25vw;
    justify-content: space-between;

    span {
      font-weight: 600;
      font-size: 1.875vw;
    }

    .close_btn {
      cursor: pointer;
      font-size: 1.875vw;
    }
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #e1e3e9;
  }

  .body_popup {
    padding: 0 6.25vw;
    margin-bottom: 1.25vw;
    height: 100%;
    display: flex;
    overflow: hidden;

    .content_popup {
      display: flex;
      width: 100%;
      flex: 1;
      gap: 1vw;
      overflow: hidden;
      margin-top: 1.25vw;

      .left_content {
        width: 25%;
        border: 0.1vw solid var(--border);
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .game_item {
          font-size: 1vw;
          padding: 0.7vw 0;
          border-top: 0.1vw solid var(--border);
          cursor: pointer;

          &.active {
            color: var(--text-color-blue);
          }
        }

        .game_item:first-child {
          border: none;
        }

        .games_container {
          flex: 1;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .game_detail_wrapper {
        position: relative;
        flex: 1;

        .gameDetails {
          display: flex;
          height: 100%;
        }
      }
    }

    .session_detail {
      display: flex;
      flex-direction: column;
      width: 0;
      transition: all 0.4s ease-in;

      button {
        display: none;
        text-align: left;
        font-weight: 600;
      }

      &.active {
        width: 20vw;
        border-left: 2px solid var(--border);
        position: unset;
        margin-left: 1vw;
        padding: 1vw 1vw 0 1vw;

        button {
          display: flex;
          align-items: center;
          gap: 1vw;
          font-size: 1vw;
          margin-bottom: 1vw;

          img {
            height: 1.2vw;
          }
        }
      }
    }
  }

  &.active {
    width: 80vw;
  }
}
