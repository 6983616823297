.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
}

.overlay.fadeOut {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity 0.5s ease-out, background-color 0.5s ease-out; /* Transition chỉ khi fade-out */
}

.loader {
  opacity: 1;
}

.loader.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease-out; /* Transition chỉ khi fade-out */
}
