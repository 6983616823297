.table_wrapper {
  width: 100%;
  border-spacing: 0 !important;

  th,
  td {
    height: 3.1vw;
    font-size: 0.875vw;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
  }
  .table_head {
    border: none;
    tr th {
      position: sticky;
      top: 0;
      font-weight: 900;
      color: var(--black-text-secondary);
      background-color: #f3f4f8;
      text-align: center;
      vertical-align: middle;
    }
    tr th:first-child {
      border-top-left-radius: 0.5vw;
    }
    tr th:last-child {
      border-top-right-radius: 0.5vw;
    }
  }

  .table_body {
    tr {
      td {
        font-weight: 500;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 0.5vw;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 0.5vw;
    }
  }
}

.test {
  &::-webkit-scrollbar {
    width: 12px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 1px solid var(--scrollbar-thumb);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.table_nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15vmin;
}
