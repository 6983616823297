.management_patient_dashboard {
  padding: 0px 7vw;
  .management_patient_dashboard_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5vw;

    .title {
      color: var(--black-text-secondary);
      font-size: 1.5vw;
      font-weight: bold;
    }

    .add_patient_to_the_widget_btn {
      font-size: 1vw;
      padding: 0.4vw 1.4vw;
      display: flex;
      align-items: center;
      gap: 0.5vw;

      img {
        width: 1vw;
      }
    }
  }

  .detail_button {
    font-size: 1vw;
  }
}
