.search_bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.3125vw 1.5vw;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.search_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b0b0b0;
}

.search_input {
  border: none;
  outline: none;
  margin-left: 0.625vw;
  flex: 1;
  font-size: 0.875vw;
  color: #666;

  &::placeholder {
    color: #b0b0b0;
  }
}
