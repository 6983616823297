.dashboard_container {
  height: 100%;
  margin: 0 7vw;
  position: relative;

  .management_patient_dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    .management_patient_dashboard_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5vw;

      .title {
        color: var(--black-text-secondary);
        font-size: 1.5vw;
        font-weight: bold;
      }
    }
  }

  .table_nodata {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vw;
    height: 100%;
    padding-bottom: 5vw;

    img {
      width: 20vw;
    }

    span:nth-child(2) {
      font-size: 1.4vw;
    }
    span:nth-child(3) {
      color: #c4c4c4;
      font-size: 0.8vw;
    }

    .create_dashboard_btn {
      font-size: 1vw;
      padding: 0.8vw 3vw;
      display: flex;
      align-items: center;
      gap: 0.5vw;

      img {
        max-width: 1vw;
      }
    }
  }
}
