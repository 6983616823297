.addPatientPopup {
  width: 65vw;
  height: 68vh;
  border-radius: 1.5vw;
  background-color: #fff;
  text-align: center;
  z-index: 10010;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .header_popup {
    width: 100%;
    padding: 1.25vw 6.25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 6vw;
    }

    span {
      font-weight: 600;
      font-size: 1.875vw;
    }

    p {
      color: #6f6c90;
      margin: 1vw 0;
      font-size: 0.9vw;
      margin-block: 0;
    }
  }

  .table_scroll {
    overflow-y: auto;
    padding: 0 6.25vw;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 0.5vw;
      // overflow: hidden;
      border: 1px solid #e1e3e9;
    }

    .column4 {
      width: 40%; // 4/10 tương đương 40%
    }

    .column2 {
      width: 20%; // 2/10 tương đương 20%
    }

    thead th {
      position: sticky;
      top: 0;
      background-color: #f3f4f8;
      z-index: 1;
    }

    th,
    td {
      font-size: 0.875vw;
      height: 3.1vw;
      text-align: center;
      border-bottom: 2px solid #e1e3e9;
    }

    td:last-child,
    th:last-child {
      border-left: 1px solid #e1e3e9;
      text-align: center;
    }

    td:nth-child(2) {
      border-right: none;
    }

    tr:last-child td {
      border-bottom: none;
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    tbody tr:hover {
      background-color: #f9f9f9;
    }

    .custom_checkbox {
      position: relative;
      width: 1.5vw;
      height: 1.5vw;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 1px solid #989898;
      border-radius: 50%;
      cursor: pointer;
      outline: none;
    }

    .custom_checkbox:checked {
      background: linear-gradient(to bottom, #3ea5e8, #1857a6);
      border: 1px solid transparent;
    }

    .custom_checkbox:checked::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.375vw;
      height: 0.75vw;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      transform: translate(-50%, -60%) rotate(45deg);
    }
  }

  .footer_popup {
    width: 100%;
    margin-bottom: 1vw;
    display: flex;
    gap: 1vw;
    padding: 0 6.25vw;
    font-weight: 900;

    button {
      font-size: 1vw;
      padding: 0.8vw 0;
      flex: 1;
      font-weight: 500;
    }
  }
}
