@import "src/assets/styles/mixins.scss";

.login_container {
  display: flex;
  height: 100vh;

  .login_left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  .login_right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .language_btn {
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #1857a6;
      padding: 0 0.4vw;
      gap: 0.4vw;
      max-height: 2.4vw;
      font-size: 1vw;
      position: absolute;
      top: 3vw;
      right: 4vw;

      img:first-child {
        height: 1.8vw;
      }

      img:nth-last-child(1) {
        height: 0.8vw;
      }
    }

    .language_options {
      @include flexColumn;
      transition: height 0.25s;
      overflow: hidden;
      justify-content: center;

      &.closed {
        height: 0;
      }

      &.open {
        width: 12.5vw;
        padding: 0.25vw;
      }

      .language_option {
        border: none;
        background-color: unset;
        text-align: left;
        margin: 0.2vw 0;
        display: flex;
        border-radius: 8px;
        padding: 0.4vw 0.4vw;
        font-size: 1vw;
        height: 2vw;

        img {
          margin-left: auto;
          height: 100%;
        }

        &.selected,
        &:hover {
          background-color: #e9f0f7;
        }
      }
    }

    .login_form {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      h1 {
        font-size: 36px;
      }

      .form_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 70%;
        margin-bottom: 40px;

        .label {
          width: 100%;
          text-align: left;
          font-weight: 400;
          padding: 10px;
        }
      }

      .login_button {
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
        padding: 10px 90px;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

@media (min-width: 2560px) {
  .login_container {
    .login_left {
      img {
        max-width: 100%;
        scale: 1.2;
      }
    }
    .login_right {
      .login_form {
        img {
          scale: 1.3;
        }

        h1 {
          font-size: 50px;
        }

        .label {
          max-width: 800px;
        }
        .input {
          max-width: 800px;
          border-radius: 12px;
        }

        .login_button {
          font-size: 30px;
          padding: 15px 140px;
        }
      }
    }
  }
}

@media (min-width: 3200px) {
  .login_container {
    .login_left {
      img {
        max-width: 100%;
        scale: 2;
      }
    }

    .login_right {
      .login_form {
        gap: 40px;

        img {
          scale: 2.5;
        }

        h1 {
          font-size: 80px;
        }

        .label {
          max-width: none !important;
          font-size: 50px;
        }

        .input {
          max-width: none !important;
          font-size: 50px;
          border-radius: 20px;
        }

        .login_button {
          font-size: 50px;
          padding: 20px 200px;
        }
      }
    }
  }
}
